@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: var(--transitionStyle);

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
    transition: var(--transitionStyle);
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100);
  /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  display: flex;
  align-items: flex-start;
  padding: 0;
  justify-content: space-between;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-top: 10px;
}

.priceValue {
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey900);
  font-size: 20px;
  line-height: 120%;
  /* Remove default margins from font */
  margin: 0;
  padding: 0;
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightRegular);
  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
  margin-top: 0;
  margin-bottom: 0;
}

.mainInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorGrey900);
  margin: 0 0 10px 0;
  line-height: 110%;
  font-size: 18px;

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.description {
  font-weight: var(--fontWeightRegular);
  color: var(--colorGrey600);
  font-size: 16px;
  line-height: 140%;
  /* Remove default margins from font */
  margin: 0;
  padding: 0;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.authorInfo {
  font-weight: var(--fontWeightRegular);
  color: var(--colorBlack);
  font-size: 16px;
  line-height: 140%;
  /* Remove default margins from font */
  margin: 0;
  padding: 0;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.bookBtn {
  composes: buttonDefault from global;
  padding: 10px 20px;
  width: auto;
  min-height: auto;
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  display: inline-block;
  text-transform: uppercase;
  margin-top: 10px;
}

.services {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 6px;
  margin-top: 6px;
  margin-bottom: 2px;



  & .servicesLeftside {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-grow: 1;
    width: 100%;
  }

  & .servicePill {
    display: flex;
    align-items: center;
  }

  & .pill {
    background-color: var(--colorGrey50);
    border: solid 1px var(--colorGrey100);
    border-radius: 100px;
    padding: 6px 14px;
    font-size: 13px;
    margin-right: 20px;
    color: var(--colorGrey900);
    line-height: 130%;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: var(--transitionStyle);

    @media screen and (min-width: 325px) and (max-width: 1375px) {
      padding: 6px 8px;
    }

    &:hover {
      transition: var(--transitionStyle);
      background-color: var(--colorWhite);
      border-color: var(--colorGrey200);
      box-shadow: var(--boxShadow);
    }

    & .delete {
      color: var(--colorFail);
      font-size: 18px;
      line-height: 100%;
      margin-left: 8px;
    }
  }

  &>button {
    font-size: 14px;
    line-height: 100%;
    min-height: auto;
    height: auto;
    position: relative;
    width: auto;
    padding: 8px 10px;
    margin-left: auto;
    white-space: nowrap;

    @media (--viewportMedium) {
      min-width: 150px;
      padding: 12px 10px;
    }
  }

  @media (max-width:768px) {
    flex-direction: column;
    align-items: flex-start;

    &>button {
      margin-left: 0;
    }
  }
}

.serviceLabel {
  font-size: 12px;
  margin-right: 6px;
  font-weight: 700;
  white-space: nowrap;
}

.membershipId {
  font-size: 13px;
  margin-right: 20px;

  display: flex;

}

.badgeIcons {
  display: flex;
  gap: 0px;
  padding-left: 4px;
}