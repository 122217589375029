.customField {
  margin-bottom: 24px;
  width: 100%;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.curencyFields{
  display: flex;
  align-items: baseline;
  gap: 10px;
}
.curency{
width: 25%;
}